import { OCLTradingSettings } from '@pages/ocl-trading/types/types';
import { Filter, FilterType } from '@shared/protos/filter';
import { ColDef } from 'ag-grid-enterprise';

type LicenceEnum = 'trial' | 'standard_paid' | 'unspecified';

type ProductGroupEnum =
  | 'unspecified'
  | 'crude'
  | 'gasoline'
  | 'naphtha'
  | 'distillate'
  | 'jet_fuel'
  | 'fuel_oil'
  | 'petrochemical'
  | 'freight'
  | 'methane'
  | 'ethane'
  | 'propane'
  | 'butane'
  | 'pentane'
  | 'ngl'
  | 'refinery_margin';

type ClientEnum = 'unspecified' | 'web' | 'mobile' | 'excel' | 'api' | 'whatsapp';
export type PermissionsEnum = 'unspecified' | 'trading' | 'live_data' | 'historical_data' | 'flux_admin' | 'trading_admin' | 'onyx_user' | 'ladder';
export type SnapshotPeriod = 'unspecified' | 'singapore_1630' | 'london_1200' | 'london_1630' | 'london_1800' | 'london_1930';

export type TradeLimit = { symbol: string; limit: number; exchange: string[] };
export type LadderLimit = { symbol: string; limit: number };
export type User = {
  id: string;
  account_name: string;
  full_name: string;
  email: string;
  superuser: boolean;
  modified_at: string;
  whatsapp_number: string;
  products: string[];
  product_groups: ProductGroupEnum[];
  client_type_list: ClientEnum[];
  license_expiry: string;
  license_type: LicenceEnum;
  snapshot_periods: SnapshotPeriod[];
  access_live_data: boolean;
  access_historical_data: boolean;
  is_flux_admin: boolean;
  api_token: string;
  client_type: ClientEnum;
  allow_trading: boolean;
  trade_limits: TradeLimit[];
  abn_trad_accounts: string[];
  is_trading_admin: boolean;
  websocket_limit: number;
  cot_product_groups: string[];
  permissions: PermissionsEnum[];
  ladder_limits: LadderLimit[];
  slippage_bp: number;
};

export type UserSettings = {
  dashboard: Record<string, any>;
  widgets: Record<string, any>;
  ocl_trading: OCLTradingSettings;
};

export const USER_COLUMNS: ColDef<User>[] = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'account_name',
    headerName: 'Account Name',
  },
  {
    field: 'full_name',
    headerName: 'Full Name',
  },
  {
    field: 'email',
    headerName: 'Email',
  },
  {
    field: 'client_type_list',
    headerName: 'Client Type List',
    valueFormatter: ({ value }) => value.join(', '),
  },
  {
    field: 'permissions',
    headerName: 'Permissions',
    valueFormatter: ({ value }) => value.join(', '),
  },
];

const PERMISSIONS_TYPE_OPTIONS: PermissionsEnum[] = ['trading', 'live_data', 'historical_data', 'flux_admin', 'trading_admin', 'onyx_user', 'ladder'];

export const USER_FILTERS: Filter[] = [
  { type: FilterType.AUTOCOMPLETE, name: 'permission', label: 'Permissions', options: PERMISSIONS_TYPE_OPTIONS },
];
