import { Order, OrderState } from '@protos/v2/order';
import { priceFormatterService } from '@services/PriceFormatterService';
import { originalAmountFormatter } from '@utils/formatter';
import { ColDef } from 'ag-grid-enterprise';
import { DurationTimerCellRenderer } from '../components/DurationTimerCellRenderer';

export const isNotLiveOrder = (order: Order) =>
  order.order_state === OrderState.EXPIRED || order.order_state === OrderState.CANCELLED || order.order_state === OrderState.FILLED;

export const ALL_ORDERS_GRID_COLUMNS: ColDef<Order>[] = [
  {
    field: 'created_timestamp',
    headerName: 'Created At',
    headerTooltip: 'Created At',
    sort: 'desc',
    valueFormatter: ({ value }) => (value ? new Date(value).toLocaleString() : ''),
  },
  {
    field: 'side',
    headerName: 'Side',
    headerTooltip: 'Side',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
    cellStyle: ({ value }) => ({ color: value?.toLowerCase().includes('buy') ? '#4189E8' : '#CD4B4B' }),
  },
  {
    field: 'symbol',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    valueFormatter: ({ value }) => (value ? (typeof value === 'string' ? value.toUpperCase() : `${value.front} / ${value.back}`.toUpperCase()) : ''),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    headerTooltip: 'Amount',
    valueFormatter: ({ value, data }) => (value ? originalAmountFormatter(value, data ? +data.original_amount : undefined) : ''),
  },
  {
    field: 'price',
    headerName: 'Price',
    headerTooltip: 'Price',
    valueFormatter: ({ value }) => (value ? priceFormatterService.formatProductPrice(value) : ''),
  },
  {
    field: 'order_state',
    headerName: 'Order State',
    headerTooltip: 'Order State',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  {
    field: 'order_type',
    headerName: 'Order Type',
    headerTooltip: 'Order Type',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  { headerName: 'Validity', headerTooltip: 'Order Validity', cellRenderer: DurationTimerCellRenderer, maxWidth: 100 },
  {
    field: 'expiry_timestamp',
    headerName: 'Expiry',
    headerTooltip: 'Expiry',
    valueFormatter: ({ value }) => (value ? new Date(value).toLocaleString() : ''),
  },
  {
    field: 'user.full_name',
    headerName: 'User',
    headerTooltip: 'User',
    valueFormatter: ({ value }) => (value ? value.toLocaleString().toUpperCase() : ''),
  },
  { field: 'account_id', headerName: 'Account', headerTooltip: 'Account' },
  {
    field: 'executed_amount',
    headerName: 'Exec. Amount',
    headerTooltip: 'Executedd Amount',
    valueFormatter: ({ value }) => (value ? value.toLocaleString() : ''),
  },
  {
    field: 'executed_avg_price',
    headerName: 'Exec. Price',
    headerTooltip: 'Executed Average Price',
    valueFormatter: ({ value }) => (value ? priceFormatterService.formatProductPrice(value) : ''),
  },
];
