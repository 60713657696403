import { Paper, Stack, Tab, Tabs } from '@mui/material';
import NotFoundPage from '@pages/NotFoundPage';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AdvancedOCLTrading } from './AdvancedOCLTrading';
import { AllOrdersGrid } from './components/AllOrdersGrid';
import { StyledAutocomplete } from './components/StyledAutocomplete';
import { ValidityInput } from './components/ValidityInput';
import { Mixed } from './Mixed/Mixed';
import { OCLPricingSheet } from './pricing-sheet/OCLPricingSheet';
import { SimpleOCLTrading } from './SimpleOCLTrading';
import { OCLTradingType } from './types/types';
import { OrderState } from '@protos/v2/order';

type Tab = { label: string; value: string; Component: React.ComponentType<any> };
const OCLTradingTabs: Tab[] = [
  { label: 'PRICING SHEET', value: OCLTradingType.PRICING, Component: OCLPricingSheet },
  { label: 'MIXED', value: OCLTradingType.MIXED, Component: Mixed },
  { label: 'B/O ONLY', value: OCLTradingType.ADVANCED, Component: AdvancedOCLTrading },
  { label: 'ORDER ENTRY', value: OCLTradingType.SIMPLE, Component: SimpleOCLTrading },
];

export const OCLTrading = () => {
  const user = useUserContext();

  const allowedOCLTrading = useMemo(() => {
    return user?.permissions.includes('ladder') && user?.ladder_limits.length;
  }, [user?.ladder_limits, user?.permissions]);

  const { activeTab: tabInURL } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [duration, setDuration] = useState(86400);

  const [selectedTab, setSelectedTab] = useState<Tab>(OCLTradingTabs[0]);
  const [selectedAccount, setSelectedAccount] = useState<string | ''>(user?.abn_trad_accounts?.[0] || '');
  const [orderState, setOrderState] = useState<OrderState[]>([OrderState.NEW, OrderState.PARTIALLY_FILLED]);
  const [selectedProductSymbol, setSelectedProductSymbol] = useState<string | ''>();

  const onTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      const tab = OCLTradingTabs.find(tab => tab.value === newValue);
      if (tab && tab.value !== tabInURL) {
        setSelectedTab(tab);
        setSearchParams({});
        if (tab.value === OCLTradingType.PRICING) {
          navigate(`/ocl-trading/${tab.value}`);
        } else {
          navigate(
            `/ocl-trading/${tab.value}?account_id=${selectedAccount}&order_state=${orderState.join(',')}${
              selectedProductSymbol ? `&product_symbol=${selectedProductSymbol}` : ''
            }`
          );
        }
      }
    },
    [tabInURL, selectedAccount, orderState, selectedProductSymbol]
  );

  useEffect(() => {
    if (!tabInURL && selectedTab) {
      const params = searchParams.toString();
      navigate(`/ocl-trading/${selectedTab.value}${params ? `?${params}` : ''}`);
    } else if (!tabInURL && !selectedTab) {
      setSelectedTab(OCLTradingTabs[0]);
    } else if (tabInURL && !selectedTab) {
      const tab = OCLTradingTabs.find(tab => tab.value === tabInURL);
      if (tab) {
        setSelectedTab(tab);
      }
    } else if (tabInURL && selectedTab && tabInURL !== selectedTab.value) {
      const tab = OCLTradingTabs.find(tab => tab.value === tabInURL);
      if (tab) {
        setSelectedTab(tab);
      }
    }
  }, [tabInURL, selectedTab, searchParams]);

  if (!allowedOCLTrading) return <NotFoundPage />;

  return (
    <Stack display="flex" flexDirection="column" flex={1} gap={1} padding={2} height="100%">
      <Stack display="flex" flexDirection="row" gap={1} alignItems="center">
        <Paper style={{ flex: 1, maxWidth: 'calc(100vw - 400px)', minWidth: 'calc(100vw - 400px)' }}>
          <Tabs value={selectedTab.value} onChange={onTabChange} variant="scrollable">
            {OCLTradingTabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} style={{ fontSize: 11 }} />
            ))}
          </Tabs>
        </Paper>

        {selectedTab.value !== 'pricing' ? (
          <StyledAutocomplete
            options={user?.abn_trad_accounts || []}
            getOptionLabel={(option: unknown) => (option as string).toUpperCase()}
            value={selectedAccount}
            onChange={(_, newValue) => setSelectedAccount(newValue || '')}
            label="Account"
            isOptionEqualToValue={(option, value) => (option as string).toUpperCase() === (value as string).toUpperCase()}
          />
        ) : (
          <ValidityInput value={duration.toString()} onChange={value => setDuration(Number(value))} />
        )}
      </Stack>

      <Paper style={{ display: 'flex', flex: 1 }} key={selectedTab.value}>
        {React.createElement(selectedTab.Component, {
          selectedAccount,
          setSelectedAccount,
          duration: selectedTab.value === 'pricing' ? duration : undefined,
        })}
      </Paper>

      {selectedTab.value !== OCLTradingType.PRICING ? (
        <Paper style={{ height: '100%' }}>
          <AllOrdersGrid selectedAccount={selectedAccount} setOrderState={setOrderState} setSelectedProductSymbol={setSelectedProductSymbol} />
        </Paper>
      ) : null}
    </Stack>
  );
};
