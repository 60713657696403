import { Outlier } from '@features/admin/outliers/outliersProtos';
import { TradingExecutionParams } from '@features/dashboard/widgets/trading-blotter/tradingBlotterTypes';
import { Analytics } from '@protos/analytics';
import { ChartSymbol, SearchSymbolResponse } from '@protos/charts';
import { CreateLadderRequest } from '@protos/ladders';
import { News } from '@protos/news';
import { PricingDAGResponse } from '@protos/pricing';
import { Task } from '@protos/tasks';
import { Order } from '@protos/v2/order';
import { ProductRiskMessage } from '@protos/v2/productRisk';
import { Alert, AlertEvent } from '@shared/protos/alerts';
import { CalendarDay } from '@shared/protos/calendar';
import { Contract, Product, ProductTenor } from '@shared/protos/product';
import { SettlementPriceResponse } from '@shared/protos/settlementPrices';
import { SettlementProductParams, SettlementProductResponse } from '@shared/protos/settlementProducts';
import {
  Execution,
  ExecutionReport,
  ExecutionVolume,
  KillSwitch,
  KillSwitchStrategy,
  MidPrice,
  OrderState,
  ParamsResponse,
  PeriodicPnLResponse,
} from '@shared/protos/trading';
import { User, UserSettings } from '@shared/protos/user';
import { Dashboard } from '@shared/protos/v2/dashboard';
import { ChartTicker } from '@shared/protos/v2/ticker';
import { DataApi, HttpClientInterface, RestApiDataLoader } from '@shared/utils/data';
import { HttpResponse, Paginated } from '@shared/utils/http';
import { formatParams } from './ApiUtils';
import { config } from './context';
import { ChartTickersParams, SettlementPriceParams, UserLimits } from './types';

export interface Options {
  method: string;
  headers: Record<string, string>;
  body?: any;
}

class ApiClient implements HttpClientInterface {
  private apiUrl: string;
  private token: string | undefined;

  constructor(apiUrl: string, token?: string) {
    this.apiUrl = apiUrl;
    this.token = token;
  }

  loader<DataType>(url: string, query?: Record<string, any>, options?: Record<string, any>): DataApi<DataType> {
    return new RestApiDataLoader<DataType>(this, url, query, options);
  }

  contractsLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<Contract> {
    return this.loader<Contract>(`${this.apiUrl}/contracts`, query, options);
  }

  symbolsLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<SearchSymbolResponse> {
    return this.loader<SearchSymbolResponse>(`${this.apiUrl}/symbols`, query, options);
  }

  productsLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<Product> {
    return this.loader<Product>(`${this.apiUrl}/products`, query, options);
  }

  usersLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<User> {
    return this.loader<User>(`${this.apiUrl}/users`, query, options);
  }

  settlementsLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<SettlementProductResponse> {
    return this.loader<SettlementProductResponse>(`${this.apiUrl}/settlements/products`, query, options);
  }

  chartLoader(symbol: string, period: string, query?: Record<string, any>, options?: Record<string, any>): DataApi<ChartTicker> {
    return this.loader<ChartTicker>(`${this.apiUrl}/tickers/charts/${symbol.toLocaleLowerCase()}/${period}`, query, options);
  }

  historicalChartLoader(symbol: string, period: string, query?: Record<string, any>, options?: Record<string, any>): DataApi<ChartTicker> {
    return this.loader<ChartTicker>(`${this.apiUrl}/tickers/ohlc/${symbol.toLocaleLowerCase()}/${period}`, query, options);
  }

  alertsLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<Alert> {
    return this.loader<Alert>(`${this.apiUrl}/alerts`, query, options);
  }

  alertEventsLoader(id: number, query?: Record<string, any>, options?: Record<string, any>): DataApi<AlertEvent> {
    return this.loader<AlertEvent>(`${this.apiUrl}/alerts/${id}/events`, query, options);
  }

  ordersLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<Order> {
    return this.loader<Order>(`${this.apiUrl}/orders`, query, options);
  }

  executionsLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<Execution> {
    return this.loader<Execution>(`${this.apiUrl}/executions`, query, options);
  }

  executionReportsLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<ExecutionReport> {
    return this.loader<ExecutionReport>(`${this.apiUrl}/executions-reports`, query, options);
  }

  outliersLoader(contractsymbol?: string, query?: Record<string, any>, options?: Record<string, any>): DataApi<Outlier> {
    return this.loader<Outlier>(`${this.apiUrl}/outliers${contractsymbol ? `/${contractsymbol}` : ''}`, query, options);
  }

  newsLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<News> {
    return this.loader<News>(`${this.apiUrl}/news`, query, options);
  }

  ladderLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<Order> {
    return this.loader<Order>(`${this.apiUrl}/ladders`, query, options);
  }

  executionVolumeLoader(query?: Record<string, any>, options?: Record<string, any>): DataApi<ExecutionVolume> {
    return this.loader<ExecutionVolume>(`${this.apiUrl}/executions/volume`, query, options);
  }

  async updateOutlier(outlier_id: number, body: any): Promise<Outlier> {
    const response = await this.patch(`${this.apiUrl}/outliers/${outlier_id}`, { body });
    return response.data as Outlier;
  }

  async getList<DataType>(url: string, options?: any): Promise<Paginated<DataType>> {
    const response = await this.get(url, options);
    return Paginated.fromResponse<DataType>(response);
  }

  async getUser(): Promise<User> {
    const response = await this.get(`${this.apiUrl}/user`);
    return response.data as User;
  }

  async getUserById(id: string): Promise<User> {
    const response = await this.get(`${this.apiUrl}/user/${id}`);
    return response.data as User;
  }

  async getUserProducts(params?: any): Promise<Product[]> {
    const response = await this.get(`${this.apiUrl}/user/products${params ? formatParams(params) : ''}`);
    return response.data as Product[];
  }

  async getUserSettings(): Promise<UserSettings> {
    const response = await this.get(`${this.apiUrl}/user/settings`);
    return response.data as UserSettings;
  }

  async updateUserSettings(settings: UserSettings): Promise<UserSettings> {
    const response = await this.patch(`${this.apiUrl}/user/settings`, { body: settings });
    return response.data as UserSettings;
  }

  async updateUserLimits(userId: string, userLimits: UserLimits): Promise<User> {
    const response = await this.patch(`${this.apiUrl}/users/${userId}`, { body: userLimits });
    return response.data as User;
  }

  async getCalendar(params?: any): Promise<CalendarDay[]> {
    const response = await this.get(`${this.apiUrl}/business-days${params ? formatParams(params) : ''}`, {
      headers: { Accept: 'text/csv', 'content-type': 'application/csv' },
    });
    return response.data as CalendarDay[];
  }

  async getProductTenors(params?: any): Promise<ProductTenor[]> {
    const response = await this.get(`${this.apiUrl}/tenors${params ? formatParams(params) : ''}`);
    return response.data as ProductTenor[];
  }

  async getSettlementPrices(params?: SettlementPriceParams): Promise<SettlementPriceResponse[]> {
    const response = await this.get(`${this.apiUrl}/snapshots/cross-section${params ? formatParams(params) : ''}`);
    return response.data as SettlementPriceResponse[];
  }

  async getAllTradeExecutions(params?: TradingExecutionParams): Promise<Paginated<Order>> {
    const response = await this.get(`${this.apiUrl}/orders${params ? formatParams(params) : ''}`);
    return Paginated.fromResponse<Order>(response);
  }

  async createDashboard(body: any): Promise<Dashboard> {
    const response = await this.post(`${this.apiUrl}/dashboards`, { body });
    return response.data as Dashboard;
  }

  async deleteDashboard(id: number): Promise<Dashboard> {
    const response = await this.delete(`${this.apiUrl}/dashboards/${id}`);
    return response.data as Dashboard;
  }

  // Internal methods
  async get(url: string, options?: any): Promise<HttpResponse> {
    return await this.request(url, { ...options, method: 'GET' });
  }

  async delete(url: string, options?: any): Promise<HttpResponse> {
    return await this.request(url, { ...options, method: 'DELETE' });
  }

  async patch(url: string, options?: any): Promise<HttpResponse> {
    return await this.request(url, { ...options, method: 'PATCH' });
  }

  async post(url: string, options?: any): Promise<HttpResponse> {
    return await this.request(url, { ...options, method: 'POST' });
  }

  async request(url: string, options: Options): Promise<HttpResponse> {
    const opts = this.getOptions(options);
    let response;
    try {
      response = await fetch(url, opts);
    } catch (err: any) {
      response = err;
      if (!response.status) response = null;
    }
    let data: any;

    if (opts.headers['content-type'] === 'application/csv') {
      data = await this.csvBody(response);
    } else {
      data = await this.jsonBody(response);
    }

    const respData = new HttpResponse(data, response);

    if (respData.status >= 400) throw respData;

    return respData;
  }

  getOptions(options: any): Options {
    const { body, method, headers, ...extra } = options;
    const opts: Options = {
      method: method || 'GET',
      headers: { ...this.getDefaultHeaders(), ...headers },
      ...extra,
    };
    if (body) {
      if (body.constructor === Object) {
        opts.body = JSON.stringify(body);
        opts.headers['content-type'] = 'application/json';
      } else opts.body = body;
    }
    return opts;
  }

  getDefaultHeaders(): Record<string, string> {
    if (!this.token) return {};
    return { authorization: `Bearer ${this.token}` };
  }

  async jsonBody(response: any) {
    if (!response || response.status === 204) return {};
    try {
      return await response.json();
    } catch (err) {
      return {};
    }
  }

  async csvBody(response: any) {
    if (!response || response.status === 204) return {};
    try {
      const csvText = await response.text();
      const lines: string[] = csvText.split('\n');
      const headers: string[] = lines[0].split(',');

      return lines.slice(1).map(line => {
        const values = line.split(',');
        return headers.reduce((acc, header, i) => {
          acc[header.trim()] = values[i]?.trim();
          return acc;
        }, {} as Record<string, string>);
      });
    } catch (err) {
      return {};
    }
  }

  async resetPassword(email: string): Promise<HttpResponse> {
    const domain = config.env.AUTH0_DOMAIN;
    const clientId = config.env.AUTH0_UI_API_CLIENT_ID;
    const url = `https://${domain}/dbconnections/change_password`;

    const options = {
      headers: { 'content-type': 'application/json' },
      body: {
        client_id: clientId,
        email,
        connection: 'Username-Password-Authentication',
      },
    };

    return await this.request(url, { ...options, method: 'POST' });
  }

  async getContract(symbol: string): Promise<Contract | undefined> {
    if (symbol !== 'TICKER') {
      const response = await this.get(`${this.apiUrl}/contracts/${symbol}`);
      return response.data as Contract;
    }

    return undefined;
  }

  async getChartTickers(symbol: string, params?: ChartTickersParams): Promise<Paginated<ChartTicker>> {
    const response = await this.get(`${this.apiUrl}/tickers/charts/${symbol.toLocaleLowerCase()}${params ? formatParams(params) : ''}`);
    return Paginated.fromResponse<ChartTicker>(response);
  }

  setToken(token: string | undefined) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  async getAlert(id?: number): Promise<Alert> {
    const response = await this.get(`${this.apiUrl}/alerts/${id}`);
    return response.data as Alert;
  }

  async deleteAlert(id?: number) {
    return await this.delete(`${this.apiUrl}/alerts/${id}`);
  }

  async getOrderById(id: string) {
    const response = await this.get(`${this.apiUrl}/orders/${id}`);
    return response.data as Order;
  }

  async getRelatedOrdersForOrder(id: string) {
    const response = await this.get(`${this.apiUrl}/orders/${id}/related`);
    return response.data as Order[];
  }

  async getProductBySymbol(symbol: string) {
    const response = await this.get(`${this.apiUrl}/products/${symbol}`);
    return response.data as Product;
  }

  async updateProduct(
    symbol: string,
    updatedFields: { enabled?: boolean; private?: boolean; default_exchange?: string; pricing_tenors?: number }
  ): Promise<Product> {
    const response = await this.patch(`${this.apiUrl}/products/${symbol}`, { body: updatedFields });
    return response.data as Product;
  }

  async getSettlementsProducts(params?: SettlementProductParams) {
    const response = await this.get(`${this.apiUrl}/settlements/products${params ? formatParams(params) : ''}`);
    return response.data as SettlementProductResponse[];
  }

  async getSettlementsForProduct(productSymbol: string, params?: any) {
    const response = await this.get(`${this.apiUrl}/settlements/products/${productSymbol}${params ? formatParams(params) : ''}`);
    return response.data as SettlementProductResponse;
  }

  async getTradingAdminRisk(accountId: string, productSymbol: string) {
    const response = await this.get(`${this.apiUrl}/risk/${accountId}/${productSymbol}`);
    return response.data as ProductRiskMessage;
  }

  async getKillSwitch(strategy: KillSwitchStrategy) {
    const response = await this.get(`${this.apiUrl}/kill-switches/${config.otcAccountId}/${strategy}`);
    return response.data as KillSwitch;
  }

  async setKillSwitch(active: boolean, strategy: KillSwitchStrategy): Promise<KillSwitch> {
    const response = await this.patch(`${this.apiUrl}/kill-switches/${config.otcAccountId}/${strategy}`, { body: { active } });
    return response.data as KillSwitch;
  }

  async getContractAnalytics(symbol: string): Promise<Analytics | undefined> {
    if (symbol !== 'TICKER') {
      const response = await this.get(`${this.apiUrl}/cot/${symbol}`);
      return response.data as Analytics;
    }

    return undefined;
  }

  async getSymbol(symbol: string): Promise<ChartSymbol | undefined> {
    if (symbol.toLocaleLowerCase() !== 'TICKER'.toLocaleLowerCase()) {
      const response = await this.get(`${this.apiUrl}/symbols/${symbol.toLocaleLowerCase()}`);
      return response.data as ChartSymbol;
    }
    return undefined;
  }

  async getAlertsForProduct(productSymbol: string) {
    const response = await this.get(`${this.apiUrl}/alerts${formatParams({ product_symbol: productSymbol })}`);
    return response.data as Alert[];
  }

  async getRelatedProductsForProduct(productSymbol: string) {
    const response = await this.get(`${this.apiUrl}/products/${productSymbol}/related`);
    return response.data as Product[];
  }

  async getPricingDAGForProduct(productSymbol: string) {
    const response = await this.get(`${this.apiUrl}/pricing/dag/${productSymbol}`);
    return response.data as PricingDAGResponse[];
  }

  async createOutlier(body: { symbol: string; start: string; end: string; period: string }): Promise<Outlier> {
    const response = await this.post(`${this.apiUrl}/outliers`, { body });
    return response.data as Outlier;
  }

  async calculateFormula(formula: string): Promise<ChartTicker[]> {
    const encodedFormula = encodeURIComponent(formula);
    const response = await this.get(`${this.apiUrl}/tickers/formula?formula=${encodedFormula}`);
    return response.data as ChartTicker[];
  }

  async getTradingParamsSchema() {
    const response = await this.get(`${this.apiUrl}/openapi.json`);
    return response.data as Record<string, any>;
  }

  async getTradingHedgerParams() {
    const response = await this.get(`${this.apiUrl}/trading-params/hedger`);
    return response.data as ParamsResponse;
  }

  async updateTradingHedgerParams(params: Record<string, any>): Promise<ParamsResponse> {
    const response = await this.post(`${this.apiUrl}/trading-params/hedger`, { body: params });
    return response.data as ParamsResponse;
  }

  async getContractPeriodicPnlData(accountId: string, period: string, productSymbol: string) {
    const response = await this.get(`${this.apiUrl}/pnl/periodic/${accountId}/${productSymbol}/${period}`);
    return response.data as PeriodicPnLResponse;
  }

  async getTradingOtcParams() {
    const response = await this.get(`${this.apiUrl}/trading-params/otc`);
    return response.data as ParamsResponse;
  }

  async updateTradingOtcParams(params: Record<string, any>): Promise<ParamsResponse> {
    const response = await this.post(`${this.apiUrl}/trading-params/otc`, { body: params });
    return response.data as ParamsResponse;
  }

  async getTasks(): Promise<Task[]> {
    const response = await this.get(`${this.apiUrl}/tasks`);
    return response.data as Task[];
  }

  async updateTask(taskName: string, body: { enabled: boolean }): Promise<Task> {
    const response = await this.patch(`${this.apiUrl}/tasks/${taskName}`, { body });
    return response.data as Task;
  }

  async getLaddersForContracts(contractSymbols: string[], user_id: string): Promise<Order[]> {
    const response = await this.get(
      `${this.apiUrl}/ladders?product_symbol=${contractSymbols.join(',')}&order_state=${OrderState.NEW}&order_state=${
        OrderState.PARTIALLY_FILLED
      }&user_id=${user_id}`
    );
    return response.data as Order[];
  }

  async createNewLadder(ladderRequest: CreateLadderRequest): Promise<Order[]> {
    const response = await this.post(`${this.apiUrl}/ladders`, { body: ladderRequest });
    return response.data as Order[];
  }

  async expireExistingLadder(symbol: string, account_id: string): Promise<any> {
    await this.delete(`${this.apiUrl}/ladders/${symbol}/${account_id}`);

    return undefined;
  }

  async reconcileExecution(executionId: string): Promise<Execution> {
    const response = await this.post(`${this.apiUrl}/reconcile-execution/${executionId}`);
    return response.data as Execution;
  }

  async createMidPrice(midPrice: MidPrice) {
    const response = await this.post(`${this.apiUrl}/mid-prices`, { body: midPrice });
    return response.data as MidPrice;
  }
}

export default ApiClient;
