import { ProductTenor } from '@protos/product';
import { ContractRisk } from '@protos/trading';
import { ButterflySymbol, Order } from '@protos/v2/order';
import { ContractRisk as ContractRiskProto, ProductRiskMessage } from '@protos/v2/productRisk';
import { SymbolTypeEnum } from './tradingAdminProtos';

const MIN_TENOR_COUNT = 12;

export const transformRiskResponse = (
  productRisk: ProductRiskMessage,
  tenors: ProductTenor[]
): { data: ContractRisk[]; version?: number; timestamp?: string } => {
  const iceSum = productRisk.tenors?.reduce((acc, tenor) => acc + Number(tenor.value_ice), 0);
  const cmeSum = productRisk.tenors?.reduce((acc, tenor) => acc + Number(tenor.value_cme), 0);
  const valueSum = productRisk.tenors?.reduce((acc, tenor) => acc + Number(tenor.value), 0);

  const sumRow = { tenorDisplay: 'SUM', tenorCode: 'SUM', cmeTenor: cmeSum, iceTenor: iceSum, tenor: valueSum, spread: 0 };

  const sortTenorsByTime = (tenorObjects: ContractRiskProto[], productSymbol: string) => {
    if (!tenorObjects) return [];
    return [...tenorObjects]?.sort((a, b) => {
      const tenorA = a.symbol.replace(productSymbol, '');
      const tenorB = b.symbol.replace(productSymbol, '');

      // Map months to numbers
      const monthMap: { [key: string]: number } = {
        f: 1,
        g: 2,
        h: 3,
        j: 4,
        k: 5,
        m: 6,
        n: 7,
        q: 8,
        u: 9,
        v: 10,
        x: 11,
        z: 12,
      };

      const monthA = tenorA[0];
      const monthB = tenorB[0];
      const yearA = parseInt(tenorA.slice(1));
      const yearB = parseInt(tenorB.slice(1));

      if (yearA !== yearB) return yearA - yearB;
      return monthMap[monthA] - monthMap[monthB];
    });
  };

  const sortedProductTenors = sortTenorsByTime(productRisk.tenors, productRisk.product_symbol);

  const monthlyTenors = tenors.filter(tenor => tenor.frequency === 'monthly');
  const visibleTenors = getVisibleTenors(sortedProductTenors, monthlyTenors);
  const dataTemplate = visibleTenors.map(tenor => ({
    tenorDisplay: tenor.display.toLocaleUpperCase(),
    tenor: 0,
    cmeTenor: 0,
    iceTenor: 0,
    tenorCode: tenor.code,
  }));

  dataTemplate.unshift(sumRow);

  if (!productRisk || typeof productRisk === 'string') return { data: dataTemplate };

  const riskResponseSymbols = productRisk.tenors?.map(tenor => tenor.symbol);

  return {
    version: productRisk.version,
    timestamp: productRisk.timestamp,
    data: dataTemplate.map(template => {
      const code = template.tenorCode;

      if (riskResponseSymbols?.includes(`${productRisk.product_symbol}${code}`)) {
        const tenorRisk = productRisk.tenors?.find(tenor => tenor.symbol === `${productRisk.product_symbol}${code}`);
        if (tenorRisk) {
          return {
            ...template,
            tenor: Number(tenorRisk.value),
            cmeTenor: Number(tenorRisk.value_cme),
            iceTenor: Number(tenorRisk.value_ice),
          } as ContractRisk;
        }
      }

      return template;
    }),
  };
};

export const transformParams = (params: any, schema: Record<string, any>) => {
  const { properties } = params;
  if (!properties) return params;

  const transformedParams = Object.entries(properties).reduce((acc, [key, value]) => {
    const { allOf, ...restValue } = value as Record<string, any>;

    if (value && allOf && allOf.length) {
      const newValue = allOf.reduce((acc, item) => {
        const schemaProperty = item.$ref.split('/').pop();
        const schemaValue = schema[schemaProperty];
        if (schemaValue && schemaValue.enum) acc.push(...schemaValue.enum);
        return acc;
      }, []);

      return { ...acc, [key]: { ...restValue, enum: newValue } };
    } else if (value && value['$ref']) {
      const newRestValue = { ...restValue };
      delete newRestValue['$ref'];

      const schemaProperty = value['$ref'].split('/').pop();
      const schemaValue = schema[schemaProperty];

      if (schemaValue && schemaValue.enum) return { ...acc, [key]: { ...newRestValue, enum: schemaValue.enum } };
    }

    return { ...acc, [key]: value };
  }, {});

  return { ...params, properties: transformedParams, required: Object.keys(properties) };
};

const findLastTenorWithValue = (sortedTenors: ContractRiskProto[]): ContractRiskProto | undefined => {
  return [...sortedTenors].reverse().find(tenor => tenor.value_ice !== '0' || tenor.value_cme !== '0' || tenor.value !== '0');
};

const getVisibleTenors = (sortedTenors: ContractRiskProto[], monthlyTenors: ProductTenor[]): ProductTenor[] => {
  const lastTenorWithValue = findLastTenorWithValue(sortedTenors);
  const startIndex = 0;

  if (!lastTenorWithValue) {
    return monthlyTenors.slice(0, MIN_TENOR_COUNT);
  }

  const tenorCode = lastTenorWithValue.symbol.slice(-3).toLowerCase();

  //monthly tenors are already sorted
  const monthlyTenorIndex = monthlyTenors.findIndex(tenor => tenor.code === tenorCode);

  if (monthlyTenorIndex === -1) {
    return monthlyTenors.slice(0, MIN_TENOR_COUNT);
  }

  return monthlyTenorIndex + 1 > MIN_TENOR_COUNT
    ? monthlyTenors.slice(startIndex, monthlyTenorIndex + 1)
    : monthlyTenors.slice(startIndex, MIN_TENOR_COUNT);
};

export const orderMatchesFilters = (order: Order, filters: Record<string, string>, limitedToAccounts?: string[]) => {
  if (filters.symbol_type && filters.symbol_type !== getOrderSymbolType(order)) return false;
  if (filters.account_id && filters.account_id.toLowerCase() !== order.account_id.toLowerCase()) return false;
  if (filters.product_symbol && !filters.product_symbol?.includes(order.product_symbol)) return false;
  if (filters.order_state && !filters.order_state?.includes(order.order_state)) return false;
  if (filters.order_type && !filters.order_type?.includes(order.order_type)) return false;
  if (filters.trading_group && filters.trading_group !== order.trading_group) return false;
  if (filters.start && new Date(filters.start) > new Date(order.created_timestamp)) return false;
  if (filters.end && new Date(filters.end) < new Date(order.created_timestamp)) return false;
  if (filters.search) {
    const searchFields = [order.id, order.account_id, order.exchange_order_id, order.product_symbol].map(x => x.toLowerCase());
    if (!searchFields.some(f => f.toLowerCase().includes(filters.search.toLowerCase()))) return false;
  }
  if (limitedToAccounts && !limitedToAccounts.includes(order.account_id)) return false;

  return true;
};

const getOrderSymbolType = (order: Order): SymbolTypeEnum => {
  if (typeof order.symbol === 'string') return SymbolTypeEnum.FLAT;

  if (typeof order === 'object' && !Array.isArray(order) && order !== null) {
    if ((order.symbol as ButterflySymbol).middle) return SymbolTypeEnum.BUTTERFLY;
    return SymbolTypeEnum.SPREAD;
  }

  return SymbolTypeEnum.UNSPECIFIED;
};
