import { RollingRowSettings } from '@features/dashboard/widgets/shared/types';
import { getBalDisplayName } from '@features/dashboard/widgets/shared/utils';
import { FULLMO_TENOR_ID } from '@protos/grids';
import { Product, ProductTenor } from '@protos/product';
import { priceFormatterService } from '@services/PriceFormatterService';
import { ColDef } from 'ag-grid-enterprise';
import { PricesSheetRow } from './hooks/usePricingSheetGridState';

export const FULLMO_TENOR_ROW = { tenorId: FULLMO_TENOR_ID, tenorName: 'FULLMO', tenorFrequency: 'monthly' };

const TENOR_COL_ID = 'tenorId';

export const ROLLING_LABEL_MAP = { daily: 'Days', weekly: 'Weeks', monthly: 'Months', quarterly: 'Quarters', yearly: 'Years' };

export const getRollingNumberInputMinMaxForSettings = (tenor: keyof RollingRowSettings) => {
  switch (tenor) {
    case 'daily':
      return { min: 0, max: 20 };
    case 'weekly':
      return { min: 0, max: 20 };
    case 'monthly':
      return { min: 0, max: 48 };
    case 'quarterly':
      return { min: 0, max: 16 };
    case 'yearly':
      return { min: 0, max: 4 };
    default:
      return { min: 0, max: 10 };
  }
};

export const tenorColumn: ColDef<PricesSheetRow> = {
  field: 'tenorName',
  colId: TENOR_COL_ID,
  headerName: '',
  suppressSpanHeaderHeight: true,
  minWidth: 80,
  maxWidth: 120,
  lockPosition: 'left',
  pinned: 'left',
  cellClass: 'ag-tenor-column',
  resizable: true,
};

export const mapColumns = (products: Product[], selectedColumns: string[], columnsOrder: string[], isMobile: boolean): ColDef<any, any>[] =>
  products
    .sort((a, b) => columnsOrder.indexOf(a.symbol) - columnsOrder.indexOf(b.symbol))
    .reduce<ColDef<any, any>[]>(
      (accumulator, { symbol, short_name, full_name }, colIndex) => [
        ...accumulator,
        {
          colId: symbol,
          headerName: short_name,
          headerTooltip: full_name,
          hide: !selectedColumns.includes(symbol),
          resizable: !isMobile,
          cellDataType: 'text',
          valueFormatter: ({ value }) => (value === null || value === undefined ? '' : priceFormatterService.formatProductPrice(value)),
          valueGetter: ({ data }) => data?.[symbol],
          valueSetter: ({ data, newValue }) => {
            if (data[symbol] === undefined) {
              return false;
            }

            data[symbol] = newValue;
            return true;
          },
          flex: 1,
          minWidth: !isMobile ? 50 : 90,
          cellClassRules: {
            'ag-col-group-even': () => colIndex % 2 === 0,
          },
        },
      ],
      [tenorColumn]
    );

export const mapRows = (tenors: ProductTenor[], products: Product[], firstWeekIndex: number, firstMonthIndex: number): PricesSheetRow[] => {
  return tenors.map(({ code, display, frequency }, index) => {
    const newRow = {
      tenorId: code,
      tenorName: getBalDisplayName(firstMonthIndex, firstWeekIndex, index, display),
      tenorFrequency: frequency,
    };
    products.forEach(({ symbol }) => {
      newRow[symbol] = '';
    });

    return newRow as PricesSheetRow;
  });
};
