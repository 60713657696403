import { Search } from '@mui/icons-material';
import { Box, Divider, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SearchItem {
  id: string;
  name: string;
  secondaryName?: string;
}

type SearchLookupProps = {
  items: SearchItem[];
};

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const ExpandableSearchLookup: React.FC<SearchLookupProps> = ({ items }) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState<SearchItem[]>(items);
  const [expanded, setExpanded] = useState(false);
  const [hoveringList, setHoveringList] = useState(false);
  const navigate = useNavigate();

  const performFiltering = useCallback(
    (value: string) => {
      const filtered = items.filter(
        item => item.name.toLowerCase().includes(value.toLowerCase()) || item.secondaryName?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredItems(filtered);
    },
    [items]
  );

  const debouncedFilter = useCallback(debounce(performFiltering, 300), [performFiltering]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    debouncedFilter(value);
  };

  const handleOutsideClick = (forceBlur?: boolean) => {
    if (forceBlur || !hoveringList) {
      setSearchValue('');
      setFilteredItems(items);
      setExpanded(false);
    }
  };

  const handleProductClick = (product: SearchItem) => {
    navigate(`/reference/products/${product.secondaryName}`);
    setHoveringList(false);
    handleOutsideClick(true);
  };

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  return (
    <Box
      id="search-box"
      sx={{
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
      onBlur={e => {
        if (e.target.id !== 'search-box') handleOutsideClick();
      }}
      onClick={(e: any) => {
        if (!e.target.dataset.closable) setExpanded(true);
      }}
      tabIndex={-1} // To make the box focusable for handling blur
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '4px 8px',
          borderRadius: '20px',
          cursor: 'pointer',
          backgroundColor: expanded ? '#757575' : 'transparent',
          transition: 'all 0.3s ease-in-out',
        }}
        onMouseEnter={() => setExpanded(true)}
      >
        {expanded && (
          <TextField
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Product Lookup"
            variant="standard"
            size="small"
            fullWidth
            autoFocus
            slotProps={{
              input: {
                disableUnderline: true,
                style: { fontSize: 11 },
              },
              htmlInput: {
                autoComplete: 'off',
              },
            }}
            sx={{
              marginLeft: '5px',
              flexGrow: 1,
              fontSize: 11,
              transition: 'all 0.2s ease-in-out',
              overflow: 'hidden',
            }}
          />
        )}
        <Search sx={{ fontSize: 15, marginLeft: expanded ? '150px' : '0', transition: 'all 0.3s ease-in-out' }} />
      </Box>
      {expanded && (
        <Box
          sx={{
            position: 'absolute',
            top: '40px',
            right: 0,
            width: '100%',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
            maxHeight: '150px',
            overflowY: 'auto',
            zIndex: 1,
            fontSize: 11,
            backgroundColor: '#757575',
          }}
          onMouseEnter={() => setHoveringList(true)}
          onMouseLeave={() => setHoveringList(false)}
        >
          <List>
            {filteredItems.length > 0 ? (
              filteredItems.map(item => (
                <>
                  <ListItemButton
                    key={item.id}
                    data-closable
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    onClick={() => handleProductClick(item)}
                  >
                    <Typography data-closable variant="body1" style={{ fontSize: 10 }}>
                      {item.name}
                    </Typography>
                    <Typography data-closable variant="body2" style={{ fontSize: 10 }}>
                      {item.secondaryName}
                    </Typography>
                  </ListItemButton>
                  <Divider />
                </>
              ))
            ) : (
              <ListItem>
                <ListItemText primaryTypographyProps={{ fontSize: 11 }} primary="No results found" />
              </ListItem>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default ExpandableSearchLookup;
